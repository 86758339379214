// dashboardReducer.js

import { FETCH_DAILY_CHECKINOUTS_MADINA, FETCH_DAILY_CHECKINOUTS_MAKKAH } from '../action/actionTypes';

const initialState = {
  dailyCheckInOutsInMadina: [],
  dailyCheckInOutsInMakkah: [],
  error: null,
};

const dashboardReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_DAILY_CHECKINOUTS_MADINA:
      console.log('Reducer handling SET_MADINA_DATA action:', action.payload);
      return {
        ...state,
        dailyCheckInOutsInMadina: action.payload,
        error: null,
      };
    case FETCH_DAILY_CHECKINOUTS_MAKKAH:
      return {
        ...state,
        dailyCheckInOutsInMakkah: action.payload,
        error: null,
      };
    case 'FETCH_DAILY_CHECKINOUTS_FAILURE':
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default dashboardReducer;
