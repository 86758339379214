import React, { Component } from 'react';
import { Dropdown } from 'react-bootstrap';
import { Link, withRouter, useHistory } from 'react-router-dom';
import { Trans } from 'react-i18next';

const Navbar = () => {

  const history = useHistory();

  const toggleOffcanvas = () => {
    document.querySelector('.sidebar-offcanvas').classList.toggle('active');
  };

  const handleLogout = () => {
    alert('logout');
    history.push('/auth');
    localStorage.clear();
  }

  // const toggleRightSidebar = () => {
  //   document.querySelector('.right-sidebar').classList.toggle('open');
  // };

  return (
    <nav className="navbar p-0 fixed-top d-flex flex-row">

      <div className="navbar-brand-wrapper d-flex d-lg-none align-items-center justify-content-center">
        <Link className="navbar-brand brand-logo-mini" to="/">HAMSYL</Link>
      </div>

      <div className="navbar-menu-wrapper flex-grow d-flex align-items-stretch">
        <button className="navbar-toggler align-self-center" type="button" onClick={() => document.body.classList.toggle('sidebar-icon-only')}>
          <span className="mdi mdi-menu"></span>
        </button>

        
        <ul className="navbar-nav w-100">
          <li className="nav-item w-100">
            {/* <form className="nav-link mt-2 mt-md-0 d-none d-lg-flex search">
              <input type="text" className="form-control" placeholder="Search products" />
            </form> */}
          </li>
        </ul>

        <ul className="navbar-nav navbar-nav-right">

       

          <li className="nav-item d-none d-lg-block">
            <a className="nav-link" href="!#" onClick={event => event.preventDefault()}>
              <i className="mdi mdi-view-grid"></i>
            </a>
          </li>

          <Dropdown alignRight as="li" className="nav-item border-left" >
            {/* <Dropdown.Toggle as="a" className="nav-link count-indicator cursor-pointer">
              <i className="mdi mdi-email"></i>
              <span className="count bg-success"></span>
            </Dropdown.Toggle> */}
            {/* <Dropdown.Menu className="navbar-dropdown preview-list">

            </Dropdown.Menu> */}
          </Dropdown>

          <Dropdown alignRight as="li" className="nav-item border-left">
            <Dropdown.Toggle as="a" className="nav-link count-indicator cursor-pointer"
              onClick={() => history.push("/notification/notification-list")}>
              <i className="mdi mdi-bell"></i>
              <span className="count bg-danger"></span>
            </Dropdown.Toggle>
            <Dropdown.Menu className="dropdown-menu navbar-dropdown preview-list">

            </Dropdown.Menu>
          </Dropdown>

          <Dropdown alignRight as="li" className="nav-item">
            <Dropdown.Toggle as="a" className="nav-link cursor-pointer no-caret">
              <div className="navbar-profile">
                <img className="img-xs rounded-circle" src={require('../../assets/images/faces/face1.jpg')} alt="profile" />
                <p className="mb-0 d-none d-sm-block navbar-profile-name">Admin</p>
                <i className="mdi mdi-menu-down d-none d-sm-block"></i>
              </div>
            </Dropdown.Toggle>

            <Dropdown.Menu className="navbar-dropdown preview-list navbar-profile-dropdown-menu">
              <h6 className="p-3 mb-0"><Trans>Profile</Trans></h6>
              <Dropdown.Divider />
              <Dropdown.Item href="!#" onClick={evt => evt.preventDefault()} className="preview-item">
                <div className="preview-thumbnail">
                  <div className="preview-icon bg-dark rounded-circle">
                    <i className="mdi mdi-settings text-success"></i>
                  </div>
                </div>
                <div className="preview-item-content">
                  <p className="preview-subject mb-1" onClick={() => history.push("/auth/new/user/role")}>Settings</p>
                </div>
              </Dropdown.Item>
              <Dropdown.Divider />
              <Dropdown.Item href="!#" onClick={evt => evt.preventDefault()} className="preview-item">
                <div className="preview-thumbnail">
                  <div className="preview-icon bg-dark rounded-circle">
                    <i className="mdi mdi-logout text-danger"></i>
                  </div>
                </div>
                <div className="preview-item-content">
                  <p className="preview-subject mb-1" onClick={handleLogout}>Log Out</p>
                </div>
              </Dropdown.Item>
              <Dropdown.Divider />
            </Dropdown.Menu>
          </Dropdown>

        </ul>

        <button className="navbar-toggler navbar-toggler-right d-lg-none align-self-center" type="button" onClick={toggleOffcanvas}>
          <span className="mdi mdi-format-line-spacing"></span>
        </button>
      </div>
    </nav>
  );
}


export default Navbar;
