
// const localEndpoint = "http://localhost:9090/";
const localEndpoint = "https://www.hamsyltravels.com/api/";


export const apiURL = process.env.NODE_ENV === 'production' ? localEndpoint : localEndpoint

const token = localStorage.getItem("@_token");

const _postApi = (url, data = {}, success = (f) => f, error = (f) => f) => {
  fetch(`${apiURL}${url}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: token,
    },
    body: JSON.stringify(data),
  })
    .then((resp) => resp.json())
    .then((response) => {

      if (response.success) {
        success(response);
      } else {
        // error(response.message);
        error(response)
      }

    })

    .catch((err) => error(err));

};

const headers = { "Content-Type": "application/json", "authorization": token }

const _fetchApi = (
  url,
  success = (f) => f,
  error = (f) => f,
  empty = (f) => f
) => {
  // const { facilityId } = store.getState().auth.user;
  let actualURL = `${url}`;
  fetch(actualURL, headers)
    .then((raw) => raw.json())
    .then((response) => {
      if (response) {
        success(response);
        
      } else {
        console.log("Empty response");
        empty();
      }
    })
    .catch((err) => {
      error(err);
    });
};

const _deleteApi = (
  route,
  data = {},
  callback = (f) => f,
  err_cb = (f) => f
) => {
  // const { facilityId, username } = store.getState().auth.user;
  // data.facilityId = facilityId;
  // data.userId = username;

  fetch(route, {
    method: "DELETE",
    headers: { "Content-Type": "application/json", },
    body: data ? JSON.stringify(data) : null,
  })
    .then(function (response) {
      // if the status of the response is greater than 400, then error is returned
      if (response.status >= 400) {
        if (err_cb) err_cb();
      }
      if (callback) callback();
    })
    .catch(function (err) {
      return err_cb(err);
    });
};

export { _postApi, _fetchApi, _deleteApi };
