import React, { useEffect, useState } from 'react'
import { Form, ProgressBar } from 'react-bootstrap'
import { fetchLatestData } from '../redux/action/customFile'
import { Alert, Badge } from 'reactstrap'

export default function HotelsList() {
    const [hotelsList, setHotelsList] = useState([])
    const [search, setSearch] = useState("")

    useEffect(() => {
        fetchLatestData(
            "hotles/get/all/list",
            {},
            (data) => {
                setHotelsList(data)
            },
            (err) => {
                console.log(err);
                alert("Error fetching")
            }
        )

    }, [])

    const rows = hotelsList.filter((item) => {
        const lowerSearch = search && search.toLowerCase();

        return (
            (item.name && item.name.toString().toLowerCase().includes(lowerSearch))
        );
    });

    return (
        <div>
            <div className="page-header">
                <h3 className="page-title">HOTELS AVAILABLE </h3>
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><a href="!#" onClick={event => event.preventDefault()}>Tables</a></li>
                        <li className="breadcrumb-item active" aria-current="page">Hotel</li>
                    </ol>
                </nav>

            </div>
            <div className='row'>
                <div className="col-lg-12 grid-margin stretch-card">
                    <div className="card">
                        <div className="card-body">
                            <div className='row'>
                                <Form.Label htmlFor="inputPassword5">Search bar</Form.Label>
                                <Form.Control
                                    type="search"
                                    aria-describedby="passwordHelpBlock"
                                    name="search"
                                    value={search}
                                    onChange={(e) => setSearch(e.target.value)}
                                    placeholder='Searh by : agent name, name, passport, staatus'
                                />
                            </div>

                            <div className="table-responsive">
                                <table className="table table-striped ">

                                    <thead>
                                        <tr>
                                            <th>NAME</th>
                                            <th>TOTAL BEDS</th>
                                            <th>AVAILABLE BEDS</th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {rows.map((item) =>
                                            <tr>
                                                <td> {item.name} </td>
                                                <td><Badge color='success'>{item.totalBeds}</Badge>  </td>
                                                <td> <Badge color='white'>{item.availableBeds}</Badge> </td>
                                            </tr>
                                        )}

                                    </tbody>
                                </table>
                                {!rows.length &&
                                    <Alert>No data found at this time.</Alert>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
