import { _fetchApi, apiURL, _postApi } from "./api";

export const fetchLatestData = (url, q = {}, callback = (f) => f, error = (f) => f) => {
    const query_string = Object.keys(q)
        .map((a) => a + "=" + q[a])
        .join("&");

    // console.log(query_string, 'sss')

    _fetchApi(
        `${apiURL}${url}?${query_string}`,
        (data) => {
            if (data.success) {
                callback(data.results);
            } else {
                error(data.message);
            }
        },
        (err) => {
            console.log(err);
        }
    );
};

export const postNewData = (
    data = {},
    url,
    callback = (f) => f,
    error = (f) => f
) => {
    _postApi(
        `${url}`,
        data,
        (data) => {
            console.log("data =======", data);
            if (data.success) {
                callback(data);
            } else {
                error(data.message);
            }
        },
        (err) => {
            error(err);
        }
    );
};