import React from 'react';
import { useEffect, useRef, useState } from 'react';
import ReactPDF, { Document, Page, Text, View, Image, Font, StyleSheet, PDFViewer } from '@react-pdf/renderer';
import { fetchLatestData } from '../../redux/action/customFile';

const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    backgroundColor: '#FFFFFF',
    padding: 20,
    fontFamily: 'Helvetica',
    fontSize: 12,
  },
  heading: {
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: 20,
    marginBottom: 10,
  },
  qrCode: {
    width: 80,
    height: 80,
    marginLeft: 'auto',
  },
  text: {
    fontSize: 15,
    marginBottom: 8,
    marginTop: 8
  },
  userId: {
    fontSize: 14,
    marginBottom: 8,
    // marginTop: 8,
    fontWeight: 'bold',
  },
  textBold: {
    fontWeight: 'bold',
  },
  textManifest1: {
    fontSize: '16px',
    fontWeight: 'bold',
    marginBottom: '5px',
    textAlign: 'center'
  },
  textManifest: {
    textAlign: 'center',
    fontSize: '14px',
    marginBottom: '10px',
  },
  space: {
    marginBottom: 20,
  },
  tableContainer: {
    flexDirection: 'row',
    marginTop: 0,
    borderWidth: 1,
    borderColor: 'black',
    borderStyle: 'solid',
  },
  tableHeader: {
    backgroundColor: '#f0f0f0',
    borderWidth: 1,
    borderColor: 'black',
    borderStyle: 'solid',
    padding: 2,
    flex: 1,
    textAlign: 'center',
    fontWeight: 'bold',
  },
  tableData: {
    borderWidth: 1,
    borderColor: 'black',
    borderStyle: 'solid',
    padding: 5,
    flex: 1,
    textAlign: 'center',
  },
  paragraph: {
    fontSize: 10,
    marginTop: 5,
    marginBottom: 0,

  },

  HeadersDataManifest: {
    fontWeight: 'bold',
    fontSize: '16px',
    marginBottom: '5px',
  },
  childsDataManifest: {
    fontSize: '14px',
  },
});


const ManifestData = ({ data, userId }) => {
  const [results, setResults] = useState([])

  const date = new Date();
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');
  const currentDate = `${day}/${month}/${year}`

  // const fetchData = (data) => {
  //   fetchLatestData(
  //     "manifest/daily/record",
  //     {},
  //     (data) => {
  //       setResults(data)
  //     },
  //     (err) => {
  //       console.log(err);
  //     }
  //   )
  // }

  useEffect(() => {
    fetchLatestData(
      "manifest/daily/record",
      {},
      (data) => {
        setResults(data)
        // alert(JSON.stringify(results))
      },
      (err) => {
        alert(err)
        console.log(err);
      }
    )
  }, [0])

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <Text style={styles.heading}>HAMSYL TRAVEL AND TOURS LTD</Text>
        <Text style={styles.textManifest1}>DATA MANIFEST </Text>
        <Text style={styles.textManifest}>{currentDate}</Text>
        <Text style={styles.space} />
        <Text style={styles.space} />

        {/* <Text> {JSON.stringify(results)}</Text> */}
        {/* <Text style={styles.textBold}>PASSENGER DETAILS</Text>
          <Text style={styles.text}>AIRLINES: Azaman</Text> */}
        {/* Adding QR Code image */}

        {/* MADINA MANIFEST */}
        <View>
          <Text style={styles.text}>DATA</Text>
          <View style={styles.tableContainer}>
            <Text style={styles.tableHeader}>NO PEOPLE</Text>
            <Text style={styles.tableHeader}>REG ID</Text>
            <Text style={styles.tableHeader}>Name</Text>
            <Text style={styles.tableHeader}>Passport</Text>
            <Text style={styles.tableHeader}>AIRLINE</Text>
          </View>
          {results.entriesToday && results.entriesToday.map((entry, index) => (
            <View key={index} style={styles.tableContainer}>
              <Text style={styles.tableData}>{entry && entry.multipleReg ? entry.multipleReg.length || 0 : 0}</Text>
              <Text style={styles.tableData}>{entry.voucherId}</Text>

              <View style={styles.tableData}>
                {entry && entry.multipleReg && Array.isArray(entry.multipleReg) ? (
                  entry.multipleReg.map((item, index) => (
                    <View key={index}>
                      <Text>{item.name}</Text>
                    </View>
                  ))
                ) : (
                  <Text>No data available</Text>
                )}
              </View>

              <View style={styles.tableData}>
                {entry && entry.multipleReg && Array.isArray(entry.multipleReg) ? (
                  entry.multipleReg.map((item, index) => (
                    <View key={index}>
                      <Text>{item.passport}</Text>
                    </View>
                  ))
                ) : (
                  <Text>No data available</Text>
                )}
              </View>

              <Text style={styles.tableData}>{entry.airline}</Text>
            </View>
          ))}
        </View>


        {/* Summary and Totals */}
        <View style={{ marginTop: '10' }}>
          <Text style={styles.text}>MANIFEST SUMMARY</Text>

          <View style={styles.tableContainer}>
            <Text style={styles.tableHeader}>S/N</Text>
            <Text style={styles.tableHeader}>REG ID</Text>
            <Text style={styles.tableHeader}>Name</Text>
            <Text style={styles.tableHeader}>Passport</Text>
            <Text style={styles.tableHeader}>Hotel MADINA</Text>
            <Text style={styles.tableHeader}>Hotel MAKKAH</Text>
            <Text style={styles.tableHeader}>Transportation Type</Text>
          </View>

          {results.entriesToday && results.entriesToday.map((entry, index) => (
            <View key={index} style={styles.tableContainer}>
              <Text style={styles.tableData}>{index + 1}</Text>
              <Text style={styles.tableData}>{entry.voucherId}</Text>
              <Text style={styles.tableData}>{entry.multipleReg[0].name}</Text>
              <Text style={styles.tableData}>{entry.multipleReg[0].passport}</Text>
              <Text style={styles.tableData}>{entry.hotelMadina}</Text>
              <Text style={styles.tableData}>{entry.hotelMakkah}</Text>
              <Text style={styles.tableData}>{entry.transportationType}</Text>
            </View>
          ))}

          {/* Other Data */}
          {results.airlinesData &&
            Object.entries(results.airlinesData).map(([airline, numberOfPeople]) => (
              <View key={airline} style={styles.tableContainer}>
                <Text style={styles.tableData}>-</Text>
                <Text style={styles.tableData}>-</Text>
                <Text style={styles.tableData}>-</Text>
                <Text style={styles.tableData}>-</Text>
                <Text style={styles.tableData}>-</Text>
                <Text style={styles.tableData}>-</Text>
                <Text style={styles.tableData}>
                  {`${airline}: ${numberOfPeople} person${numberOfPeople !== 1 ? 's' : ''}`}
                </Text>
              </View>
            ))}

          {results.hotelsMadinaData &&
            Object.entries(results.hotelsMadinaData).map(([hotel, numberOfPeople]) => (
              <View key={hotel} style={styles.tableContainer}>
                <Text style={styles.tableData}>-</Text>
                <Text style={styles.tableData}>-</Text>
                <Text style={styles.tableData}>-</Text>
                <Text style={styles.tableData}>-</Text>
                <Text style={styles.tableData}>
                  {`${hotel}: ${numberOfPeople} person${numberOfPeople !== 1 ? 's' : ''}`}
                </Text>
                <Text style={styles.tableData}>-</Text>
                <Text style={styles.tableData}>-</Text>
              </View>
            ))}

          {results.hotelsMakkahData &&
            Object.entries(results.hotelsMakkahData).map(([hotel, numberOfPeople]) => (
              <View key={hotel} style={styles.tableContainer}>
                <Text style={styles.tableData}>-</Text>
                <Text style={styles.tableData}>-</Text>
                <Text style={styles.tableData}>-</Text>
                <Text style={styles.tableData}>-</Text>
                <Text style={styles.tableData}>-</Text>
                <Text style={styles.tableData}>
                  {`${hotel}: ${numberOfPeople} person${numberOfPeople !== 1 ? 's' : ''}`}
                </Text>
                <Text style={styles.tableData}>-</Text>
              </View>
            ))}

          <View style={styles.tableContainer}>
            <Text style={styles.tableData}>-</Text>
            <Text style={styles.tableData}>-</Text>
            <Text style={styles.tableData}>-</Text>
            <Text style={styles.tableData}>-</Text>
            <Text style={styles.tableData}>-</Text>
            <Text style={styles.tableData}>-</Text>
            <Text style={styles.tableData}>-</Text>
          </View>

          {/* Total Check-In and Check-Out for MADINA and MAKKAH */}
          <View>
            <Text style={styles.HeadersDataManifest}>USERS</Text>
            <Text style={styles.childsDataManifest}>{results.totalMultipleReg}</Text>
          </View>

          <View>
            <Text style={styles.HeadersDataManifest}>CHECK-IN MADINA</Text>
            <Text style={styles.childsDataManifest}>{results.totalCheckinMadina}</Text>
          </View>

          <View>
            <Text style={styles.HeadersDataManifest}>CHECK-OUT MADINA</Text>
            <Text style={styles.childsDataManifest}>{results.totalCheckoutMadina}</Text>
          </View>

          <View>
            <Text style={styles.HeadersDataManifest}>CHECK-IN MAKKAH</Text>
            <Text style={styles.childsDataManifest}>{results.totalCheckinMakkah}</Text>
          </View>

          <View>
            <Text style={styles.HeadersDataManifest}>CHECK-OUT MAKKAH</Text>
            <Text style={styles.childsDataManifest}>{results.totalCheckoutMakkah}</Text>
          </View>
        </View>


      </Page>
    </Document>
  )
}

export default ManifestData;
