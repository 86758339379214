// useAuth.js

import React, { useState, createContext, useContext } from 'react';
import { Alert } from 'react-bootstrap';

// Create a context to manage authentication state
const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [showAlert, setShowAlert] = useState(false);

  const login = () => {
    // You might want to add authentication logic here (e.g., API calls, etc.)
    // For simplicity, just set isAuthenticated to true
    setIsAuthenticated(true);
    setShowAlert(true);
  };

  const logout = () => {
    // You might want to add logout logic here (e.g., clearing tokens, etc.)
    // For simplicity, just set isAuthenticated to false
    setIsAuthenticated(false);
    setShowAlert(true);
  };

  const handleAlertClose = () => {
    setShowAlert(false);
  };

  return (
    <>
      <AuthContext.Provider value={{ isAuthenticated, login, logout }}>
        {children}
      </AuthContext.Provider>

      {showAlert && (
        <Alert variant="info" onClose={handleAlertClose} dismissible>
          ‼️ Action performed successfully!
        </Alert>
      )}
    </>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }

  return context;
};
