import React, { useEffect, useState } from 'react';
import { Form, Row, Col, Container, InputGroup, Card, Button } from 'react-bootstrap';
import { BsPlus, BsDash, BsFileEarmarkPdf, BsArrowLeftShort } from 'react-icons/bs';
import { useHistory, useParams } from 'react-router-dom';
import { useAuth } from '../../auth/useAuth';
import { AiFillCloseCircle } from 'react-icons/ai';
import { PDFViewer } from '@react-pdf/renderer';
import PdfFile from './generator/PdfFile';
import { fetchLatestData, postNewData } from '../../redux/action/customFile';
// import { Input } from 'reactstrap';

export default function WebVoucher() {
    const history = useHistory();
    const params = useParams();
    const [openFile, setOpenFile] = useState('')
    const [currentStep, setCurrentStep] = useState(1); // Track the current step
    const date = new Date();
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    const regDate = `${year}-${month}-${day}`
    const [hotelsList, setHotelsList] = useState([])

    const initialFormData = {
        agentName: '',
        airline: '',
        cityMadina: 'MADINA',
        nightsMadina: '',
        roomTypeMadina: '',
        hotelMadina: '',
        checkInMadina: '',
        checkOutMadina: '',

        cityMakkah: 'MAKKAH',
        nightsMakkah: '',
        roomTypeMakkah: '',
        hotelMakkah: '',
        checkInMakkah: '',
        checkOutMakkah: '',
        transportationType: '',
    };

    const initialPassportData = {
        name: '',
        passport: '',
    };

    const [formData, setFormData] = useState(initialFormData);

    const [passportData, setPassportData] = useState([initialPassportData]);

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const userId = localStorage.getItem('userId');

    const handleSubmit = (e) => {
        e.preventDefault();
        if (
            formData.agentName === "" ||
            formData.airline === "" ||
            passportData[0].name === "" ||
            passportData[0].passport === "" ||
            formData.cityMadina === "" ||
            formData.nightsMadina === "" ||
            formData.roomTypeMadina === "" ||
            formData.hotelMadina === "" ||
            formData.checkInMadina === "" ||
            formData.checkOutMadina === "" ||
            formData.cityMakkah === "" ||
            formData.nightsMakkah === "" ||
            formData.roomTypeMakkah === "" ||
            formData.hotelMakkah === "" ||
            formData.checkInMakkah === "" ||
            formData.checkOutMakkah === "" ||
            formData.transportationType === ""
        ) {
            alert("invalid form")
        } else {
            const formData = new FormData(e.target);
            const data = Object.fromEntries(formData.entries());
            const insert = "voucher/create";

            const update = `web/form/voucher/update/${params.id}`;
            const sentData = params.id > 0 ? update : insert;

            postNewData(
                { data, multipleReg: passportData, userId, regDate },
                sentData,
                (data) => {
                    alert(params.id > 0 ? "Updated successfully" : "Submit successfully")
                    // setOpenFile(data.results);
                    setFormData(initialFormData);
                    setPassportData([initialPassportData]);
                    history.goBack()
                },
                (err) => {
                    alert(err.message)
                }
            )

            // setOpenFile(true)
        }
    };

    const handlePassportChange = (index, field, value) => {
        const updatedPassports = [...passportData];
        updatedPassports[index][field] = value;
        setPassportData(updatedPassports);
    };

    const handleAddPassport = () => {
        setPassportData([...passportData, { name: '', passport: '' }]);
    };

    const handleRemovePassport = (indexToRemove) => {
        const updatedPassportData = passportData.filter((_, index) => index !== indexToRemove);
        setPassportData(updatedPassportData);
    };

    useEffect(() => {
        fetchLatestData(
            "web/voucher/byId",
            { id: params.id },
            (data) => {
                setFormData(data || "");
                setPassportData(data.multipleReg)
            },
            (err) => {
                console.log(err);
                alert("Error fetching")
            }
        )

        fetchLatestData(
            "hotles/get/all/list",
            {},
            (data) => {
                setHotelsList(data)
            },
            (err) => {
                console.log(err);
                alert("Error fetching")
            }
        )
    }, [])

    const makkahHotels = hotelsList.filter((item) => item.city === `MAKKAH`)
    const madinaHotels = hotelsList.filter((item) => item.city === `MADINA`)

    return (
        <>
            {openFile ?
                <>
                    <Button color='danger' onClick={() => setOpenFile(false)}
                    >close <AiFillCloseCircle size={20} /></Button>
                    <PDFViewer style={{ width: '100%', height: '800px' }}>
                        <PdfFile data={openFile} />
                    </PDFViewer>
                </>
                :
                <>
                    <div style={{ display: 'flex' }}>
                        <Button onClick={() => history.goBack()} variant='success'>
                            <BsArrowLeftShort color='info' /> Back
                        </Button>
                    </div>

                    <Card>
                        {/* {JSON.stringify(initialFormData)} */}
                        <Card.Header>
                            <h1>NEW VOUCHER</h1>
                        </Card.Header>

                        <Card.Body>

                            <Form onSubmit={handleSubmit}>

                                <Row>

                                    <Col lg={6} sm={12}>
                                        <Form.Group controlId="agentName">
                                            <Form.Label>Agent Name</Form.Label>
                                            <Form.Control type="text" name="agentName" value={formData.agentName} onChange={handleChange} />
                                        </Form.Group>
                                    </Col>

                                    <Col lg={6} xs={12}>
                                        <Form.Group controlId="airline">
                                            <Form.Label>Airline</Form.Label>
                                            <Form.Control type="text" name="airline" value={formData.airline} onChange={handleChange} />
                                        </Form.Group>
                                    </Col>


                                    {passportData.map((passport, index) => (
                                        <>

                                            <Col lg={6} sm={12}>
                                                <Form.Group controlId={`name${index}`}>
                                                    <Form.Label>Name</Form.Label>
                                                    <Form.Control type="text"
                                                        value={passport.name}
                                                        onChange={(e) => handlePassportChange(index, 'name', e.target.value)}
                                                    />
                                                </Form.Group>
                                            </Col>

                                            <Col lg={6} sm={12}>
                                                <Form.Group controlId={`passport${index}`}>
                                                    <Form.Label>Passport</Form.Label>
                                                    <InputGroup>
                                                        <Form.Control
                                                            type="text"
                                                            value={passport.passport}
                                                            onChange={(e) => handlePassportChange(index, 'passport', e.target.value)} />
                                                        <Button variant="outline-secondary" onClick={handleAddPassport}>
                                                            <BsPlus />
                                                        </Button>
                                                        <Button variant="outline-secondary"
                                                            onClick={() => handleRemovePassport(index)}>
                                                            <BsDash />
                                                        </Button>
                                                    </InputGroup>
                                                </Form.Group>
                                            </Col>

                                        </>
                                    ))}

                                    <Col lg={6} sm={12}>
                                        <Form.Group controlId="cityMadina">
                                            <Form.Label>City</Form.Label>
                                            <Form.Control as="select" name="cityMadina" style={{ color: "white", backgroundColor: "#616161" }}
                                                value={formData.cityMadina} onChange={handleChange} >
                                                <option value="MADINA">MADINA</option>
                                            </Form.Control>
                                        </Form.Group>
                                    </Col>

                                    <Col lg={6} sm={12}>
                                        <Form.Group controlId="nightsMadina">
                                            <Form.Label>No Of Nights</Form.Label>
                                            <Form.Control type="number" name="nightsMadina" value={formData.nightsMadina} onChange={handleChange} />
                                        </Form.Group>
                                    </Col>



                                    <Col lg={6} sm={12}>
                                        <Form.Group controlId="roomTypeMadina">
                                            <Form.Label>Room Type</Form.Label>
                                            <Form.Control as="select" name="roomTypeMadina" value={formData.roomTypeMadina} onChange={handleChange} >
                                                <option value="">--select--</option>
                                                <option value="SINGLE">SINGLE</option>
                                                <option value="SHARING">SHARING</option>
                                            </Form.Control>
                                        </Form.Group>
                                    </Col>


                                    <Col lg={6} sm={12}>
                                        <Form.Group>
                                            <Form.Label>Hotel</Form.Label>
                                            <Form.Control
                                                as="select"
                                                name="hotelMadina"
                                                value={formData.hotelMadina}
                                                onChange={handleChange}
                                            >
                                                <option>--select--</option>
                                                {madinaHotels.map((item) => (
                                                    <option key={item.id}>{item.name}</option>
                                                ))}
                                            </Form.Control>
                                        </Form.Group>
                                    </Col>


                                    <Col lg={6} sm={12}>
                                        <Form.Group controlId="checkInMadina">
                                            <Form.Label>Check-In</Form.Label>
                                            <Form.Control type="date" name="checkInMadina" value={formData.checkInMadina} onChange={handleChange} />
                                        </Form.Group>
                                    </Col>

                                    <Col lg={6} sm={12}>
                                        <Form.Group controlId="checkOutMadina">
                                            <Form.Label>Check-Out</Form.Label>
                                            <Form.Control type="date"
                                                name="checkOutMadina"
                                                value={formData.checkOutMadina}
                                                onChange={handleChange} />
                                        </Form.Group>
                                    </Col>

                                    <Col lg={6} sm={12}>
                                        <Form.Group controlId="cityMakkah">
                                            <Form.Label>City</Form.Label>
                                            <Form.Control as="select" name="cityMakkah" value={formData.cityMakkah} onChange={handleChange} style={{ color: "white", backgroundColor: "#616161" }}>
                                                <option value="MAKKAH">MAKKAH</option>
                                            </Form.Control>
                                        </Form.Group>
                                    </Col>

                                    <Col lg={6} sm={12}>
                                        <Form.Group controlId="nightsMakkah">
                                            <Form.Label>No Of Nights</Form.Label>
                                            <Form.Control type="number" name="nightsMakkah" value={formData.nightsMakkah} onChange={handleChange} />
                                        </Form.Group>
                                    </Col>

                                    <Col lg={6} sm={12}>
                                        <Form.Group controlId="roomTypeMakkah">
                                            <Form.Label>Room Type</Form.Label>
                                            <Form.Control as="select" name="roomTypeMakkah" value={formData.roomTypeMakkah} onChange={handleChange}  >
                                                <option value="">--select--</option>
                                                <option value="SINGLE">SINGLE</option>
                                                <option value="SHARING">SHARING</option>
                                            </Form.Control>
                                        </Form.Group>
                                    </Col>

                                    <Col lg={6} sm={12}>
                                        <Form.Group>
                                            <Form.Label>Hotel</Form.Label>
                                            <Form.Control
                                                as="select"
                                                name="hotelMakkah"
                                                value={formData.hotelMakkah}
                                                onChange={handleChange}
                                            >
                                                <option>--select--</option>
                                                {makkahHotels.map((item) => (
                                                    <option key={item.id}>{item.name}</option>
                                                ))}
                                            </Form.Control>
                                        </Form.Group>
                                    </Col>

                                    <Col lg={6} sm={12}>
                                        <Form.Group controlId="checkInMakkah">
                                            <Form.Label>Check-In</Form.Label>
                                            <Form.Control type="date" name="checkInMakkah" value={formData.checkInMakkah} onChange={handleChange} />
                                        </Form.Group>
                                    </Col>

                                    <Col lg={6} sm={12}>
                                        <Form.Group controlId="checkOutMakkah">
                                            <Form.Label>Check-Out</Form.Label>
                                            <Form.Control type="date"
                                                name="checkOutMakkah"
                                                value={formData.checkOutMakkah}
                                                onChange={handleChange} />
                                        </Form.Group>
                                    </Col>

                                    <Col lg={6} sm={12}>
                                        <Form.Group>
                                            <Form.Label>City</Form.Label>
                                            <Form.Control as="select"
                                                name="transportationType"
                                                value={formData.transportationType}
                                                onChange={handleChange}>
                                                <option>--select--</option>
                                                <option>BUS</option>
                                                <option>SEDAN</option>
                                                <option>VIP</option>
                                            </Form.Control>
                                        </Form.Group>
                                    </Col>

                                    <Col lg={6} sm={12}>
                                        <Form.Group>
                                            <Form.Label>Payment Status</Form.Label>
                                            <Form.Control as="select"
                                                name="paymentStatus"
                                                value={formData.paymentStatus}
                                                onChange={handleChange}>
                                                <option>--select--</option>
                                                <option>PENDING</option>
                                                <option>PAID</option>
                                            </Form.Control>
                                        </Form.Group>
                                    </Col>

                                </Row>

                                <center>
                                    <Button variant="primary" size='lg' type="submit">
                                        <BsFileEarmarkPdf /> Generate PDF
                                    </Button>
                                </center>

                            </Form>
                        </Card.Body>
                    </Card>

                </>
            }
        </>
    );

}
