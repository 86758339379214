import React, { useState, useEffect, useRef } from 'react';
import { withRouter } from 'react-router-dom';
import './App.scss';
import AppRoutes from './AppRoutes';
import Navbar from './shared/Navbar';
import Sidebar from './shared/Sidebar';
import Footer from './shared/Footer';
import { withTranslation } from "react-i18next";

const App = ({ location, i18n }) => {
  const [isFullPageLayout, setIsFullPageLayout] = useState(false);
  const [isOnline, setIsOnline] = useState(window.navigator.onLine);
  const [prevOnlineStatus, setPrevOnlineStatus] = useState(true);

  const bodyRef = useRef(document.querySelector('body'));

  useEffect(() => {
    onRouteChanged();
    checkNetworkStatus(); // Initial check for network status
    window.addEventListener('online', handleNetworkChange);
    window.addEventListener('offline', handleNetworkChange);

    return () => {
      window.removeEventListener('online', handleNetworkChange);
      window.removeEventListener('offline', handleNetworkChange);
    };
  }, [location]);

  const onRouteChanged = () => {
    console.log("ROUTE CHANGED");

    if (location.pathname === '/layout/RtlLayout') {
      bodyRef.current.classList.add('rtl');
      i18n.changeLanguage('ar');
    } else {
      bodyRef.current.classList.remove('rtl');
      i18n.changeLanguage('en');
    }

    window.scrollTo(0, 0);

    const fullPageLayoutRoutes = ['/auth', '/web/apply-for-vouche/', '/user-pages/register-1', '/user-pages/lockscreen', '/error-pages/error-404', '/error-pages/error-500', '/general-pages/landing-page'];

    const isFullPage = fullPageLayoutRoutes.includes(location.pathname);

    setIsFullPageLayout(isFullPage);

    if (isFullPage) {
      bodyRef.current.classList.add('full-page-wrapper');
    } else {
      bodyRef.current.classList.remove('full-page-wrapper');
    }
  };

  const navbarComponent = !isFullPageLayout ? <Navbar /> : null;
  const sidebarComponent = !isFullPageLayout ? <Sidebar /> : null;
  const footerComponent = !isFullPageLayout ? <Footer /> : null;

  const checkNetworkStatus = () => {
    setIsOnline(window.navigator.onLine);
  };

  const handleNetworkChange = () => {
    const isCurrentlyOnline = window.navigator.onLine;
    setIsOnline(isCurrentlyOnline);

    if (!prevOnlineStatus && isCurrentlyOnline) {
      alert('You are back online!');
    }

    setPrevOnlineStatus(isCurrentlyOnline);
  };

  return (
    <>
      <div className="container-scroller">
        {sidebarComponent}
        <div className="container-fluid page-body-wrapper">
          {navbarComponent}
          <div className="main-panel">
            <div className="content-wrapper">
              <AppRoutes />
            </div>
            {footerComponent}
          </div>
        </div>
      </div>
    </>
  );
};

export default withTranslation()(withRouter(App));
