import React, { useState } from 'react';
import { Button, Card, CardBody, CardHeader, Col, Form, Input, Label, Row } from 'reactstrap';
import { postNewData } from '../../redux/action/customFile';
import { useHistory } from 'react-router-dom';

export default function AgentNew() {
  const _formData = {
    agentName: '',
    phoneNumber: ''
  }
  const [formData, setFormData] = useState(_formData);
  const [errors, setErrors] = useState({});
  const history = useHistory();

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value.toUpperCase(),
    });
    // Clear the error for the field being modified
    if (errors[name]) {
      setErrors({
        ...errors,
        [name]: ''
      });
    }
  };

  const validate = () => {
    const newErrors = {};
    if (!formData.agentName) {
      newErrors.agentName = 'Agent name is required';
    }
    if (!formData.phoneNumber) {
      newErrors.phoneNumber = 'Phone number is required';
    } else if (!/^\d{11}$/.test(formData.phoneNumber)) {
      newErrors.phoneNumber = 'Phone number must be 11 digits';
    }
    return newErrors;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const newErrors = validate();
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }
    postNewData(
      formData,
      "agent/create/new",
      (data) => {
        alert(data.message);
        setFormData(_formData);
        history.push("/agent/new/form");
      },
      (err) => {
        alert("Internal error: " + err);
      }
    );
  };

  return (
    <div>
      <div className="page-header">
        <h3 className="page-title">NEW AGENT</h3>
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item"><a href="!#" onClick={event => event.preventDefault()}>Dashboard</a></li>
            <li className="breadcrumb-item active" aria-current="page">agent</li>
          </ol>
        </nav>
      </div>
      <Card>
        <CardHeader>CREATE NEW AGENT</CardHeader>
        <CardBody>
          <Form onSubmit={handleSubmit}>
            <Row>
              <Col md="6">
                <Label>Agent name</Label>
                <Input
                  type='text'
                  name='agentName'
                  value={formData.agentName}
                  onChange={handleInputChange}
                />
                {errors.agentName && <p className="text-danger">{errors.agentName}</p>}
              </Col>
              <Col md="6">
                <Label>Phone Number</Label>
                <Input
                  type='number'
                  name='phoneNumber'
                  value={formData.phoneNumber}
                  onChange={handleInputChange}
                />
                {errors.phoneNumber && <p className="text-danger">{errors.phoneNumber}</p>}
              </Col>
            </Row>
            <center className='mt-4'>
              <Button color="primary" size='lg' type='submit'>Submit</Button>
            </center>
          </Form>
        </CardBody>
      </Card>
    </div>
  );
}
