import React, { useEffect, useState } from 'react';
import { Tab, Tabs, Table, Form, Modal } from 'react-bootstrap';
import { Card, Spinner, Alert, Button } from 'reactstrap';
import { useHistory } from "react-router-dom"
import { BsArrowLeftShort } from "react-icons/bs"
import { AiFillCloseCircle, AiFillEye } from "react-icons/ai"
import { PDFViewer } from '@react-pdf/renderer';
import { fetchLatestData } from '../redux/action/customFile';
import PdfFile from '../components/voucher/generator/PdfFile';

function ActiveInOut() {
    const [results, setResults] = useState([])
    const [pdfFile, setPdfFile] = useState("")
    const [activeCheckinsMadina, setActiveCheckinsMadina] = useState([])
    const [activeCheckinsMakkah, setActiveCheckinsMakkah] = useState([])
    const history = useHistory()

    const handleEditClick = (item) => {
        // Implement the logic for handling edit
        setPdfFile(item);
    };


    const handleCurrentIN = () => {
        fetchLatestData(
            "voucher/actve/in/out",
            {},
            (data) => {
                console.log("results", data)
                setResults(data && data)
                setActiveCheckinsMadina(data.activeCheckinsMadina)
                setActiveCheckinsMakkah(data.activeCheckinsMakkah)
            },
            (err) => {
                console.log(err)
            }
        )
    }

    useEffect(() => {
        handleCurrentIN()
    }, [])

  

    // const totalMultipleRegLengthCheckIn = dataResponse.checkinList.reduce((totalLength, data) => {
    //     return totalLength + data.multipleReg.length;
    // }, 0);

    // const totalMultipleRegLengthCheckOutList = dataResponse.checkoutList.reduce((totalLength, data) => {
    //     return totalLength + data.multipleReg.length;
    // }, 0);


    return (
        <div>

            {/* {JSON.stringify(results.activeCheckinsMakkah)} */}

            {pdfFile ?
                <>
                    <Button color='danger' onClick={() => setPdfFile("")}
                    >close <AiFillCloseCircle size={20} /></Button>
                    <PDFViewer style={{ width: '100%', height: '800px' }}>
                        <PdfFile data={pdfFile} />
                    </PDFViewer>
                </> :
                <>
                    <div style={{ display: 'flex' }}>
                        <Button onClick={() => history.goBack()} color='success' className='mb-2' size='20'>
                            <BsArrowLeftShort color='secondary' />
                            Back
                        </Button>

                    </div>


                    <Card>
                        <Tabs defaultActiveKey="check-in" id="justify-tab-example" className="mb-3" justify>
                            <Tab eventKey="check-in" title="MADINA">
                                {/* <Form.Group className="mb-3">
                                    <Form.Control
                                        type="text"
                                        placeholder="Search Check-In"
                                        value={searchCheckIn}
                                        onChange={handleCheckInSearch}
                                    />
                                </Form.Group> */}
                                {/* <div style={{ fontSize: "20px", fontWeight: "bold" }}>
                                    TOTAL: <span style={{ color: "white" }}>{totalMultipleRegLengthCheckIn}</span>
                                </div> */}
                                <div className='table-responsive'>
                                    <Table striped bordered hover>
                                        <thead>
                                            <tr>
                                                <th>ID </th>
                                                <th>REG ID</th>
                                                <th>PEOPLE NUMBER</th>
                                                <th>AIRLINE</th>
                                                <th>AGENT NAME</th>
                                                <th>NAME</th>
                                                <th>PASSPORT</th>
                                                <th>Check-in Date</th>
                                                <th>Hotel Name</th>
                                                <th>Room Type</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {activeCheckinsMadina && activeCheckinsMadina.map((item, index) => (
                                                <tr key={index}>
                                                    <td>{index + 1}</td>
                                                    <td>{item.voucherId}</td>
                                                    <td>{item.multipleReg.length}</td>
                                                    <td>{item.airline}</td>
                                                    <td>{item.agentName}</td>
                                                    <td>{item.multipleReg[0].name}</td>
                                                    <td>{item.multipleReg[0].passport}</td>
                                                    <td><span className='text-success cursor-pointer'>{item.checkInMadina}</span></td>
                                                    <td>{item.hotelMadina}</td>
                                                    <td>{item.roomTypeMadina}</td>
                                                    {/* <td>{item.to}</td> */}
                                                    <td>
                                                        <Button color="primary" onClick={() => handleEditClick(item)}>
                                                            <AiFillEye />   View
                                                        </Button>
                                                    </td>

                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                </div>


                            </Tab>

                            <Tab eventKey="check-out" title="MAKKKAH">
                                {/* <Form.Group className="mb-3">
                                    <Form.Control
                                        type="text"
                                        placeholder="Search Check-Out"
                                        value={searchCheckOut}
                                        onChange={handleCheckOutSearch}
                                    />
                                </Form.Group> */}
                                {/* <div style={{ fontSize: "20px", fontWeight: "bold" }}>
                                    TOTAL: <span style={{ color: "white" }}>{totalMultipleRegLengthCheckOutList}</span>
                                </div> */}
                                <div className='table-responsive'>
                                    <Table striped bordered hover>
                                        <thead>
                                            <tr>
                                                <th>ID </th>
                                                <th>REG ID</th>
                                                <th>PEOPLE NUMBER</th>
                                                <th>AIRLINE</th>
                                                <th>AGENT NAME</th>
                                                <th>NAME</th>
                                                <th>PASSPORT</th>
                                                <th>Check-in Date</th>
                                                <th>Hotel Name</th>
                                                <th>Room Type</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {activeCheckinsMakkah.map((item, index) => (
                                                <tr key={index}>
                                                    <td>{index + 1}</td>
                                                    <td>{item.voucherId}</td>
                                                    <td>{item.multipleReg.length}</td>
                                                    <td>{item.airline}</td>
                                                    <td>{item.agentName}</td>
                                                    <td>{item.multipleReg[0].name}</td>
                                                    <td>{item.multipleReg[0].passport}</td>
                                                    <td><span className='text-success cursor-pointer'>{item.checkInMakkah}</span></td>
                                                    <td>{item.hotelMadina}</td>
                                                    <td>{item.roomTypeMadina}</td>
                                                    {/* <td>{item.to}</td> */}
                                                    <td>
                                                        <Button color="primary" onClick={() => handleEditClick(item)}>
                                                            <AiFillEye />   View
                                                        </Button>
                                                    </td>

                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                </div>

                                {/* {filteredCheckOutData.length === 0 && !searchCheckOut && <Spinner animation="border" />}
                        {filteredCheckOutData.length === 0 && searchCheckOut && (
                            <Alert>No related data found in the Check-Out table</Alert>
                        )} */}

                            </Tab>
                        </Tabs>
                    </Card>
                </>
            }
        </div>
    );
}

export default ActiveInOut;
