// index.js

import { combineReducers } from 'redux';
import dashboardReducer from './dashboardReducer';

const rootReducer = combineReducers({
  dashboard: dashboardReducer,
  // Add other reducers here if needed
});

export default rootReducer;
