import React, { Component, Suspense, lazy } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import Spinner from '../app/shared/Spinner';
import WebVoucher from './components/voucher/WebVoucher';
import ActiveInOut from './tables/ActiveInOut';
import ManifestData from './tables/dataSearch/ManifestData';
import Loader from './shared/Loader';
import HotelsList from './tables/HotelsList';
import AgentList from './components/agent/AgentList';
import AgentNew from './components/agent/AgentNew';

const RegisterUsers = lazy(() => import('./components/users/register/RegisterCard'));
const NewUser = lazy(() => import('./user-pages/NewUser'));
const NewVoucher = lazy(() => import('./components/voucher/NewVoucher'));
const Notification = lazy(() => import('./components/notification/Notification'));
const Madina = lazy(() => import('./components/departures'));
const Makkah = lazy(() => import('./components/arrivals'));
const Vouchers = lazy(() => import('./components/voucher/Vouchers'));
const Dashboard = lazy(() => import('./dashboard/Dashboard'));
const DataList = lazy(() => import('./tables/dataSearch/DataList'));
const DeclarationVoucherList = lazy(() => import('./tables/declarationVoucher/DeclarationVoucherList'));
const DeclineRecordList = lazy(() => import('./components/voucher/DeclineRecordList'));
const DateSearch = lazy(() => import('./components/voucher/DateSearch'));

const Buttons = lazy(() => import('./basic-ui/Buttons'));
const Dropdowns = lazy(() => import('./basic-ui/Dropdowns'));
const Typography = lazy(() => import('./basic-ui/Typography'));

const BasicElements = lazy(() => import('./form-elements/BasicElements'));

const BasicTable = lazy(() => import('./tables/BasicTable'));
const RecordList = lazy(() => import('./tables/RecordList'));

const Mdi = lazy(() => import('./icons/Mdi'));

const ChartJs = lazy(() => import('./charts/ChartJs'));

const Error404 = lazy(() => import('./error-pages/Error404'));
const Error500 = lazy(() => import('./error-pages/Error500'));

const Login = lazy(() => import('./user-pages/Login'));
const Register1 = lazy(() => import('./user-pages/Register'));

// voucher-date-search

class AppRoutes extends Component {

  render() {
    return (
      <Suspense fallback={<Loader />}>
        <Switch>
          <Route exact path="/dashboard" component={Dashboard} />
          <Route exact path="/data/makkah" component={Makkah} />
          <Route exact path="/data/madina" component={Madina} />

          <Route exact path="/vouher/active/in" component={ActiveInOut} />

          <Route exact path="/voucher/create-new-voucher/:id?" component={NewVoucher} />
          <Route exact path="/voucher/create-web-voucher/:id?" component={WebVoucher} />
          <Route exact path="/voucher/lists" component={Vouchers} />
          <Route exact path="/data/registered-users" component={RegisterUsers} />

          <Route exact path="/data/manifestData" component={ManifestData} />

          <Route path="/basic-ui/buttons" component={Buttons} />
          <Route path="/basic-ui/dropdowns" component={Dropdowns} />
          <Route path="/basic-ui/typography" component={Typography} />

          <Route path="/form-Elements/basic-elements" component={BasicElements} />

          <Route path="/tables/basic-table" component={BasicTable} />
          <Route path="/tables/record-list" component={RecordList} />
          <Route path="/data/search-list" component={DataList} />
          <Route path="/hotel/list/available" component={HotelsList} />

          <Route path="/agent/list/table" component={AgentList} />
          <Route path="/agent/new/form" component={AgentNew} />

          <Route path="/data/voucher-date-search" component={DateSearch} />
          <Route path="/notification/notification-list" component={Notification} />
          <Route path="/declaration/voucher-list" component={DeclarationVoucherList} />
          <Route path="/declaration/voucher-decline-record" component={DeclineRecordList} />

          <Route path="/icons/mdi" component={Mdi} />

          <Route path="/charts/chart-js" component={ChartJs} />


          <Route path="/auth/new/user/role" component={NewUser} />
          <Route path="/auth" component={Login} />
          <Route path="/user-pages/register-1" component={Register1} />

          <Route path="/error-pages/error-404" component={Error404} />
          <Route path="/error-pages/error-500" component={Error500} />


          <Redirect to="/auth" />

        </Switch>
      </Suspense>
    );
  }
}

export default AppRoutes;